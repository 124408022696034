@import "../../../App.scss";
// MemberCard.module.scss
.wrapper {
	position: relative;
	margin-top: 22px;
	&::before {
		content: '';
		position: absolute;
		top: -103px; // Змістіть листочок до верху картки
		left: 70%; // Центрування по горизонталі
		transform: translateX(-50%); // Точне центрування листочка
		width: 100px; // Ширина листочка
		height: 105px; // Висота листочка
		background: url('../../../content_images/plant.svg') no-repeat center center;
		background-size: cover;
		z-index: 10; // Вище за інші елементи
	  }
}
.card {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 420px;
	margin: 135px 20px 0 20px;
	border: 1px solid $main-color; // Колір межі з вашого зображення
	border-radius: 20px; // М'які краї
	overflow: hidden; // Обрізати вміст за рамки
	background-color: $main-color;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Легка тінь для піднятого вигляду
	padding: 6px;
@include mobile {
	height: 500px;
}
	.photo {
		width: 100%;
		height: auto; // Збереження пропорцій фото
		object-fit: cover; // Заповнення простору фото без втрати пропорцій
		border-radius: 20px;
	}
}
.icons {
	width: 30px;
	height: 30px;
	margin-top: 10px;
}
.info {
	padding: 8px; // Поле всередині для тексту
	text-align: center; // Текст по центру
	color: white;
	.name {
		margin: 8px 0;
		font-size: 20px;
		font-weight: 700;
		line-height: 21px;
	}
	.role {
		margin-top: 8px 0 12px 0;
		font-size: 14px;
		font-size: 18px;
		line-height: 25px;
	}
}
