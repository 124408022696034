@import "../../App.scss";

.team {
	width: 100%;
	max-width: $core-wrapper-width;
	margin: 0 auto;
}
.wrapper {
	max-width: $core-wrapper-width;
	margin: 0 auto;
	// display: flex;
	// justify-content: center;
	// gap: 10px;
	// @include mobile {
	// 	gap: 5px;
	// }
}
.carouselstyles {
	height: 650px;
	display: flex;
	justify-content: space-between;
	@include mobile {
		height: 690px;

	}
}
.carouselitemstyles {
	height: 620px;
	@include mobile {
		width: 255px;
	}
}
.titleContainer {
	width: 100%;
	color: $main-color;
	.images {
		width: 140px;
		height: 93px;
		margin: 0 auto;
		display: block;
		position: relative;

		.image {
			position: absolute;
			width: 90px;
			height: 90px;
		}
		.topImg {
			top: 0;
			right: 0;
		}
		.bottomImg {
			left: 0;
			bottom: 0;
		}
	}
	.title {
		font-size: 48px;
		font-weight: 400;
		line-height: 55px;
		text-align: center;
	}
}
