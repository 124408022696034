@import "../../../App.scss";
// MemberCard.module.scss
.articleCard {
	color: #171313;
	cursor: pointer;
	max-width: 360px;
	@include tablet {
		max-width: 100%;
	}
	.imgBlock {
		max-width: 100%;
		width: 360px;
		height: 300px;
		display: flex; // включаємо flexbox для контейнера
		align-items: center; // центруємо зображення по вертикалі
		justify-content: center;
		@include tablet {
			width: 100%;
			height: 100%;
		}
		.img {
			max-width: 100%;
			max-height: 300px;
			object-fit: cover;
		}
	}
	.title {
		font-size: 20px;
		font-weight: 700;
		line-height: 21px;
		margin: 16px 0 8px 0;
		@include tablet {
			text-align: center;
		}
	}
	.category {
		font-size: 18px;
		font-weight: 300;
		line-height: 11.52px;
		display: inline-block;

		padding: 10px;
		border: 1px solid #0c4a30;
		border-radius: 20px;
		@include tablet {
			display: block;
			text-align: center;
		}
	}
}
