@import "../../App.scss";

.values {
	background-color: $main-color;
	padding: 80px 0;
	margin-top: 120px;
	.title {
		font-size: 48px;
		font-weight: 400;
		line-height: 55px;
		text-align: center;
		color: white;
	}
}
.items {
	max-width: $core-wrapper-width;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;

	.item {
		width: 100%;
		max-width: 360px;
		.image {
			width: 275px;
			height: 275px;
			display: block;
			margin: 0 auto -100px auto;
		}
		.info {
			padding: 80px 17px 17px 17px;
			color: $main-color;
			border-radius: 20px;
			border: 3px solid #ffad94;
			background-color: white;
			height: 223px;
			.title {
				text-align: center;
				font-size: 20px;
				font-weight: 700;
				line-height: 21px;
				color: $main-color;
			}
			.text {
				font-size: 18px;
				font-weight: 500;
				line-height: 20px;
				text-align: left;
				margin-top: 8px;
			}
		}
	}
	@include tablet{
		flex-direction: column;
		margin: 10px;
		.item {
			max-width: 100%;
			.info {
				height: auto;
				.title {
					text-align: center;
					font-size: 20px;
					font-weight: 700;
					line-height: 21px;
				}
			}
		}
	}
}

.images {
	width: 40%;
	height: 449px;
	.image {
		width: 399px;
		height: 430px;
	}
}
.wrapper {
	max-width: $core-wrapper-width;
	display: flex;
	margin: 0 auto;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	vertical-align: middle;
	padding: 22px;
	@include tablet {
		flex-direction: column;
	}
}
