@import "../../App.scss";
.partners {
	padding: 60px 0;
	.title {
		font-size: 48px;
		font-weight: 400;
		line-height: 55px;
		text-align: center;
		color: $main-color;
	}

	.conteiner {
		max-width: $core-wrapper-width;
		margin: 0 auto;
		// display: flex;
		// justify-content: space-between;
		// flex-wrap: wrap;
		.partner {
			width: auto;
			// height: auto;
			max-width: 100%;
			height: 100px;
			margin-top: 35px;
			object-fit: contain;
		}
	}
}
.carouselstyles {
	// height: 650px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	vertical-align: middle;
}
.carouselitemstyles {
	display: flex;
	justify-content: center;
	align-items: center;
	// width: auto;
	padding: 10px;
}
