@import "../../App.scss";

.mission {
	width: 100%;
	background-color: white;
	padding: 120px 0;
	@include tablet {
		padding: 60px 0;
	}
}

.info {
	width: 60%;
	.title {
		font-size: 36px;
		font-weight: 400;
		line-height: 42px;
		color: #e84514;
	}
	.text {
		color: #171313;
		font-size: 18px;
		font-weight: 300;
		line-height: 12px;
	}
	@include tablet{
		width: 100%;
	}
	@include mobile {
		.title {
			font-size: 20px;
			line-height:23.12px;
			font-weight: 400;
		}
		.text {
			color: #171313;
			font-size: 10px;
			font-weight: 300;
			line-height: 12px;
		}
	}
}

.image {
	width: 399px;
	height: 430px;
	@include mobile {
		width: 286px;
		height: 307px;
	}
}

.wrapper {
	max-width: $core-wrapper-width;
	display: flex;
	margin: 0 auto;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	vertical-align: middle;
	padding: 22px;
	@include tablet {
		flex-direction: column;
	}
	@include mobile {
		flex-direction: column;
		padding: 10px;
	}
}
