@import "../../App.scss";

.button {
	border-radius: 8px;
	background-color: #04745C;
	color:white;
	cursor: pointer;
	font-size: 14px;
	font-weight: 600;
	max-width: 250px;
	padding: 15px 24px;
	position: relative;
	text-align: center;
	// position: absolute; /* або fixed залежно від вашого випадку */
	// top: 50%;
	// left: 50%; /* замінено right на left для коректного центрування */
	z-index: 100;
	// @include tablet {
	// 	padding: 20px; // Зменшено відступи для планшетів
	// 	flex-direction: column;
	// }
	transition: 0.5s all;
	&:hover {
		background: #059673;
	}
	&:active {
		background: #10B98D;
	}
}